import * as React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import imageLogoceohfoot from '../images/logo-ceoh-foot.png'

export default function (props) {
  return (

    <Container>
      <Row className='align-items-center'>
        <Col>
          <p className='h4'>
            Interested in learning more about an upcoming cohort?
          </p>
          <p>
            Click below, and someone will be in touch soon.
          </p>
          <p className='h4'>
            <a href='mailto:ceoh@pcgus.com?subject=CE-Oh! Cohort Inquiry'>ceoh@pcgus.com</a>
          </p>
        </Col>

        <Col md={{span: 4}} className='text-end'>
          <Image
            alt='C E O logo'
            src={imageLogoceohfoot}
            className='img-fluid'
          />
        </Col>
      </Row>
    </Container>

  )
}
